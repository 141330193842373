.page{
  display: flex; 
  justify-Content: center; 
}
.content {
  width: 100%;
  max-width: 600px;
}
.tournamentContent{
  display: flex;
  flex-direction: column;
  width: 100%;
  }

.tournamentCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tournamentCardContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  /*padding:  3px;*/
  min-height: 70px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  width: 100%;
}

.characteristicsTournament{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.tournamentColumn {
  max-width: 600px;
  min-Height: 70px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  background: #FFFFFF;
  border-radius: 10px;
}
.tournamentName{
  text-align: center;
  color: black;
  font-family: inherit;
  font-size: 30px;
}

.tournamentVenue {
  color: #719A70;
  font-size: 15;
  margin-left: 5;
}

.tournamentType {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
}

.tournamentVenue {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
}

.tournamentTypeTitle {
  color: #7F7F7F;
  font-size: 15;
}

.tournamentTypeValue {
  color: #719A70;
  font-size: 15;
}

.tournamentVenueTitle {
  color: #7F7F7F;
  font-size: 15;
}

.tournamentVenueValue {
  color: #719A70;
  font-size: 15;
}

.categoryRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.categoryColumn {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  width: inherit;
}

.categoryTitle{
  width: 30%;
  color: #7F7F7F;
  font-weight: "bold";
  font-size: 15;
}

.categoryName{
  width: 60%;
  color: #719A70;
  font-size: 15;
}

.categoryPlaceTitle{
  width: 30%;
  color: #7F7F7F;
  font-size: 15;
}

.categoryPlace{
  color: #719A70;
  font-size: 15;
  width: 30%;
}

.categoryPointsTitle{
  color: #7F7F7F;
  font-weight: bold;
  font-size: 15;
  width: 30%;
}

.categoryPoints{
  color: #719A70;
  font-weight: bold;
  font-size: 15;
  width: 70%;
}

.total{
  margin-top: 20px;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  align-items: center;
}

.totalTitle{
  color: black;
  font-family: inherit;
  font-size: 30px;
}

.totalValue {
  color: #719A70;
  font-family: inherit;
  font-size: 30px;
}