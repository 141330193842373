.page{
  display: flex; 
  justify-Content: center; 
}

.content {
  width: 100%;
  max-width: 600px;
}

.DatePicker{
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  column-gap: 5px;
}
