.header {
    background: #FF3333;
    width: 100vh;
    color: white;
    position: fixed;  
    top: 0;  
    z-index: 100; 
    width: 100%;  
    padding: 5px;
    
  }

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }
  