.app {
  background-color: #f7f8fc;
  min-height: 100vh;
  overflow: hidden;
  background-image: url(./photo.png);
  background-size: cover; 

}

.content {
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 7px;
  margin-top: 80px;
}